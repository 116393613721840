import env from "src/env.json";

export const debugLog = (data: unknown) => {
  if (env.environment === "prod" && process.env.NODE_ENV === "production") {
    return null
  }
  console.log(data);
};

export const debugInfo = (data: unknown) => {
  if (env.environment === "prod" && process.env.NODE_ENV === "production") {
    return null
  }
  console.info(data);
};
