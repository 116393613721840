import React from "react";
import env from "src/env.json";
import { Close } from "src/constants/Icons";
export const TestHeader = () => {
  const [isClose, setIsClose] = React.useState(false);
  const { environment } = env;

  const handleClose = () => setIsClose(true);

  if (environment === "prd") return null;

  return (
    <header
      className={`flex justify-around bg-red-600 py-1 text-white ${isClose && "hidden"}`}
    >
      <div />
      <p>Você está acessando o ambiente de testes!</p>
      <button
        onClick={handleClose}
        className="aspect-square rounded-md p-1 hover:bg-white/15"
      >
        <Close className="size-3" />
      </button>
    </header>
  );
};
