import { useQuery } from '@tanstack/react-query';

import { checkout } from 'src/services';
import { CheckoutError } from 'src/types/error';

const useCheckout = (token: string) => {
  const { data, isFetching, error } = useQuery<CheckoutType, CheckoutError>({
    queryKey: ['checkout', token],
    queryFn: () => checkout(token),
    enabled: !!token,
    retry: false,
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching, error };
};

export default useCheckout;
