const GlobeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
  </svg>
);

const MailIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect width="20" height="16" x="2" y="4" rx="2" />
    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
  </svg>
);

const Contact: React.FC = () => {
  return (
    <main className="my-5 flex flex-col items-center justify-center px-4 text-center">
      <h4 className="font-bold">Contatos</h4>

      <div className="flex flex-col items-center gap-6 sm:flex-row">
        <a
          href="https://www.pay2b.com.br"
          className="flex items-center gap-2 text-gray-600 transition-colors hover:text-gray-900"
        >
          <GlobeIcon />
          www.pay2b.com.br
        </a>

        <a
          href="mailto:contato@pay2b.io"
          className="flex items-center gap-2 text-gray-600 transition-colors hover:text-gray-900"
        >
          <MailIcon />
          contato@pay2b.io
        </a>
      </div>
    </main>
  );
};

export default Contact;
