const ResponseStatus = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  FUTURE: "FUTURE",
  WAITING_PAYMENT_PIX: "WAITING_PAYMENT_PIX",
  WAITING_PAYMENT_BANK_SLIP: "WAITING_PAYMENT_BANK_SLIP",
  PRE_AUTHORIZATION_ERROR: "PRE_AUTHORIZATION_ERROR",
  PRE_AUTHORIZATION_WAITING: "PRE_AUTHORIZATION_WAITING",
  CANCELLED_BY_GATEWAY: "CANCELED_BY_GATEWAY",
}

export default ResponseStatus