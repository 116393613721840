import ResponseStatus from "./api";
import ApiPaymentMethods from "./apiPaymentMethods";
import RoutesPath from "./RoutesPath";

const waitingPaymentRoutes = {
  [ResponseStatus.WAITING_PAYMENT_BANK_SLIP]: RoutesPath.BANK_SLIP,
  [ResponseStatus.WAITING_PAYMENT_PIX]: RoutesPath.PIX,
  [ResponseStatus.PRE_AUTHORIZATION_WAITING]: RoutesPath.CREDIT_CARD_PENDING,
};

const confirmedPaymentRoutes = {
  [ApiPaymentMethods.PIX]: RoutesPath.PIX_SUCCESS,
  [ApiPaymentMethods.CREDIT_CARD]: RoutesPath.CREDIT_CARD_SUCCESS,
  [ApiPaymentMethods.BANK_SLIP]: RoutesPath.BANK_SLIP_SUCCESS,
};

const errorPaymentRoutes = {
  [ApiPaymentMethods.CREDIT_CARD]: {
    [ResponseStatus.PRE_AUTHORIZATION_ERROR]: RoutesPath.CREDIT_CARD_ERROR,
    [ResponseStatus.CANCELLED_BY_GATEWAY]: RoutesPath.CREDIT_CARD_ERROR,
  },
  [ApiPaymentMethods.PIX]: {
    [ResponseStatus.PRE_AUTHORIZATION_ERROR]: RoutesPath.PIX_ERROR,
  },
};

const scheduledPaymentRoutes = {
  [ResponseStatus.FUTURE]: RoutesPath.CREDIT_CARD_SCHEDULED,
};

export { waitingPaymentRoutes, confirmedPaymentRoutes, errorPaymentRoutes, scheduledPaymentRoutes };