import React from "react";

import { CurrencyHelper } from "src/utils";

import { Strings } from "src/constants";

type OrderSummaryCardProps = {
  order?: CheckoutType;
};

const SummaryHeader: React.FC = () => (
  <div className="flex h-5 flex-col items-start justify-center gap-2 self-stretch">
    <div className="inline-flex items-center justify-center gap-2">
      <div className="text-base font-semibold uppercase leading-tight text-color-neutral-base-5">
        {Strings.SUMMARY}
      </div>
    </div>
  </div>
);

const ProductInfo: React.FC<{ description?: string }> = ({ description }) => (
  <div className="inline-flex items-center justify-center gap-8 self-stretch py-2">
    <div className="shrink grow basis-0 text-base font-normal leading-normal text-color-neutral-base-5">
      {Strings.PRODUCT}
    </div>
    <div className="text-color-neutral-strong text-base font-normal leading-normal">
      {description}
    </div>
  </div>
);

const Divider: React.FC = () => (
  <div className="inline-flex items-start justify-start gap-2 self-stretch py-2">
    <div className="h-[0px] shrink grow basis-0 border border-color-neutral-base-2 bg-color-neutral-base-2"></div>
  </div>
);

const TotalAmount: React.FC<{ amount: number }> = ({ amount }) => (
  <div className="inline-flex items-center justify-center gap-4 self-stretch py-2">
    <div className="shrink grow basis-0 text-base font-bold leading-normal text-color-neutral-base-5">
      Total
    </div>
    <div className="text-color-neutral-strong text-base font-bold leading-normal">
      {CurrencyHelper.formatBRCurrency(amount)}
    </div>
  </div>
);

const OrderSummaryCard: React.FC<OrderSummaryCardProps> = ({ order }) => {
  const type = order?.insurance?.type;
  const totalAmount = order?.checkoutPreferences?.totalAmount ?? 0;
  return (
    <div className="inline-flex w-full flex-col items-center justify-center gap-8 rounded-lg border border-black/0 bg-color-neutral-soft p-8 shadow lg:w-[400px]">
      <SummaryHeader />
      <div className="flex h-auto flex-col items-center justify-center self-stretch">
        <ProductInfo description={type?.description} />
        <Divider />
        <TotalAmount amount={totalAmount} />
      </div>
    </div>
  );
};

export default OrderSummaryCard;
